let mobileMenu = document.querySelector('[data-menu]');

document.querySelector('[data-menu-open]')
    .addEventListener('click', () => {
        mobileMenu.classList.add('open');
    });

document.querySelector('[data-menu-close]')
    .addEventListener('click', () => {
        mobileMenu.classList.remove('open');
    });
