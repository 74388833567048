document.querySelectorAll('[data-modal]').forEach((elem) => {
    let modal = document.getElementById(elem.dataset.modal);

    elem.addEventListener('click', (event) => {
        modal.showModal();
        event.preventDefault();
    });
});

document.addEventListener('click', (event) => {
    let modal = event.target.closest('dialog');

    if (!modal) return;

    if (event.target == modal
    || "modal" in event.target.dataset
    || "modalClose" in event.target.dataset) {
        modal.close();
    }
});
