document.querySelectorAll('input[type=tel]').forEach((input) => {
  input.addEventListener('input', (event) => {
    event.target.value = event.target.value
      .replace(/(\+7|\D)/g, '')
      .replace(/(\d{0,10})\d*/, '+7 ($1')
      .replace(/(\d{3})(\d)/, '$1) $2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{2})(\d)/, '$1-$2');
  });
});

